<template>
  <main>
    <b-container>
      <p class="main-title">
        Tájékoztatók
      </p>
      <b-card>
        <p class="tajekoztato-title">
          Tájékoztató az ivartalanításról
        </p>
        <p class="tajekoztato-text">
          A kutyák-macskák műtéti ivartalanítása sokszor okoz nehéz döntést a tulajdonos számára, pedig a nem
          tenyésztési célból tartott egyedek ivartalanítása hasznos mind az állat, mind a gazdája számára.
        </p>
        <p class="tajekoztato-text">
          Az ivartalanítás fő célja a rendszeresen jelentkező ivarzások és nem kívánatos párzások megelőzése. A véletlen
          párzások eredményeképpen a kidobott, kóbor állatok száma oly mértékben nő, amivel az állatvédő egyesületek és
          menhelyek képtelenek lépést tartani.
        </p>
        <p class="tajekoztato-text">
          <span class="tajekoztato-higlight1">Nőstény macskáknál</span> nem elhanyagolandó szempont, hogy ivarzás során
          az állatok viselkedése jellegzetesen megváltozik, ami a tulajdonos számára sokszor nehezen tolerálható.
          Mindezektől a problémáktól ivartalanítással mentesülhetünk, de több betegség rizikója is csökken, erre néhány
          példa az alábbiakban. Az ivartalanítással megelőzhető a szobában tartott ivarérett <span
            class="tajekoztato-higlight1"
          >kandúr macskáknál</span> gyakori a kellemetlenséget szagú hím nemi hormonokkal
          teli vizeletükkel jelöljék meg bútorokat, tárgyakat a lakásban. Kóborlása során rengeteg veszélynek van
          kitéve: gázolás, verekedés, mérgezés, fertőzések (vér és nyál útján rengeteg baktériumos és víruso betegséggel
          fertőződhet meg). A legnagyobb veszély, hogy a macskák között verekedéssel terjedő vírusos betegségek (macska
          leukózis, macska AIDS, fertőző hashártyagyulladás) gyógyíthatatlanok, hosszú szenvedés után az állat halálát
          okozzák.
        </p>
        <p class="tajekoztato-text">
          <span class="tajekoztato-higlight1">Szukák</span> esetében megelőzhető a nem kívánt szaporulat és a tüzelés
          okozta kellemetlenségek, nullára redukálható a méhgyulladás, a petefészek- és a méh daganatai valamint az
          álvemhesség, továbbá a vemhességgel és az elléssel járó kockázat. Az idejében ivartalanított szukák esetében
          minimális lehet az emlődaganat kialakulása is.
        </p>
        <p class="tajekoztato-text">
          <span class="tajekoztato-higlight1">Kanoknál</span> az ivartalanítással csökken az idős kan kutyákban
          viszonylag gyakran kialakuló prosztata megnagyobbodás, prosztata-, heredaganat kialakulásának esélye, csökken
          a más kanokkal szembeni agresszív viselkedés, megszűnik a szuka kutyák utáni vágy miatti csavargás, kóborlás,
          ami során a kan kutya rengeteg veszélynek van kitéve (gázolás, bántalmazás, mérgezés, stb.)
        </p>
        <p class="tajekoztato-text">
          Minél későbbre toljuk ki az ivartalanítás időpontját, annál nagyobb a kockázat, komplikációk esélye, annál
          jobban viseli meg az állatot, hosszabb a regenerálódási idő, illetve annál nagyobb/hosszabb lehet a vágási seb
          is.
        </p>
        <p class="tajekoztato-text">
          Macskáknál és a kisebb testű kutyáknál én a korai ivartalanítást javaslom 3 - 6 hónapos kor között. Nagyobb
          testű kutyáknál több tanulmány szerint érdemes megvárni szukáknál az ivarérést, tüzelést, így 8-9 hónapos
          kortól ajánlom.
        </p>
        <p class="tajekoztato-text">
          Gyakran merül fel az a tévhit, hogy az ivartalanítás miatt elhíznak kedvenceink. Az ivartalanítás után valóban
          elhízhatnak állataink, amennyiben nem igényei szerint etetjük. Az ivartalanítás után az állat nemi aktivitása
          megszűnik, így több időt és energiát fordíthat a táplálkozásra, pihenésre. Fontos tehát hogy a műtét után
          odafigyeljünk táplálkozásukra, ne etessük túl Őket, és aktívan mozgassuk, foglalkozzunk velük.
        </p>
        <p class="tajekoztato-text">
          Mint minden altatásos műtét ez is kockázattal jár, a kockázati szintet a műtét előtti fizikális vizsgálattal
          és a kórelőzmények alapos átbeszélésével mérjük fel, illetve további kiegészítő vizsgálat elvégzését
          javasoljuk (vérvizsgálat: máj-, vesefunkció, véralvadási paraméterek vizsgálata, szív ultrahang, EKG) az
          alaposabb tájékozódás, állapotfelmérés érdekében.
        </p>
        <app-gallery
          :images="getImages()"
          class="mt-4"
        />
      </b-card>
    </b-container>
  </main>
</template>

<script>
import AppGallery from '../components/gallery'

const context = require.context('../assets/images/ivartalanitas/', false, /\.jpg$/)
const thumbContext = require.context('../assets/images/ivartalanitas/thumb/', false, /\.jpg$/)
const images = context.keys().map((image) => {
  return {
    image: context(image),
    thumb: thumbContext(image),
    class: image.includes('_w') ? 'wide' : '',
  }
})
export default {
  name: 'AltalanosOlt',
  components: {
    AppGallery,
  },
  methods: {
    getImages () {
      return images
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../sass/settings";

.card {
  border-radius: 0;
  border: none;
  padding: 60px 40px;

  .card-body {
    margin: 0;
    padding: 0;
  }

  ul {
    margin: 24px 0 8px;
    list-style: none;

    li {
      margin: 0;

      &::before {
        content: "\2022";
        color: $primary;
        font-weight: bolder;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

@media screen and (max-width: 765px) {
  main {
    background-color: #fff;

    .main-title {
      margin-left: 20px;
    }
  }
  .container {
    margin: 0;
    padding: 0;
    width: 100vw;
    max-width: 100%;

    & > .card {
      width: calc(100vw - 20px);
      margin: 0;
      padding: 20px;
    }
  }
}
</style>
