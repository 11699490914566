<template>
  <div
    class="gallery"
  >
    <div
      v-for="(image, index) in images"
      :key="`image-${index}`"
      v-b-modal="`modal-${index}`"
      :class="image.class"
    >
      <img
        :src="image.thumb"
        loading="lazy"
        alt=""
      >
      <b-modal
        :id="`modal-${index}`"
        size="xl"
        centered
        hide-header
        hide-footer
      >
        <img
          :src="image.image"
          loading="lazy"
          alt=""
        >
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RendeloKepek',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  mounted () {
  },
  unmounted () {
  },
}
</script>

<style lang="scss" scoped>
@import '../sass/settings';

.gallery {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  justify-items: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  > * {
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }

  > .wide {
    grid-column: span 2;

    @media screen and (max-width: 768px) {
      grid-column: 1;
    }
  }

  img {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
  }
}
</style>

<style lang="scss">
.modal-dialog-centered {
  width: fit-content;
  padding: 0;
  max-width: 100vw !important;
  margin: 0 auto;
}

.modal-content {
  border: none;
  border-radius: 0;
  background-color: transparent;
  width: fit-content;

  .modal-body {
    padding: 0;
  }

  img {
    max-height: calc(100vh - 80px);
    max-width: 100vw;
    margin: 0;
    cursor: auto;
  }
}
</style>
