import { render, staticRenderFns } from "./rolunk.vue?vue&type=template&id=7e2d8737&scoped=true"
import script from "./rolunk.vue?vue&type=script&lang=js"
export * from "./rolunk.vue?vue&type=script&lang=js"
import style0 from "./rolunk.vue?vue&type=style&index=0&id=7e2d8737&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e2d8737",
  null
  
)

export default component.exports