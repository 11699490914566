<template>
  <main>
    <b-container>
      <app-gallery :images="getImages()" />
    </b-container>
  </main>
</template>

<script>
import AppGallery from '../../components/gallery'
const context = require.context('../../assets/images/galeria/rendelo/', false, /\.jpg$/)
const thumbContext = require.context('../../assets/images/galeria/rendelo/thumb/', false, /\.jpg$/)
const images = context.keys().map((image) => {
  return {
    image: context(image),
    thumb: thumbContext(image),
    class: image.includes('_w') ? 'wide' : '',
  }
})
export default {
  name: 'RendeloKepek',
  components: {
    AppGallery
  },
  methods: {
    getImages () {
      return images
    },
  }
}
</script>

