<template>
  <main>
    <b-container>
      <p class="main-title">
        Árlista
      </p>
      <b-card>
        <p class="ar-text">
          Áraink tájékoztató jellegűek, a gyógyszerköltség esetenként változhat.
        </p>
        <p class="ar-title">
          Általános
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_alt"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Kötés felhelyezése
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_kotes"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Kutya oltások
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_kutya"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Macska oltások
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_macska"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Vadászgörény oltások
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_goreny"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Nyúl oltások
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_nyul"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Gyors tesztek
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_gyors_tesztek"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Infúziós kezelés
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_infuzio"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Műtétek
        </p>
        <p class="ar-desc">
          MINIMÁL INVAZÍV TECHNIKA (lehető legkisebb, egy bemeneti vágási seb (kb.0,5-1 cm), felszívódó varrat, minden
          ivarszerv eltávolításával.<br>
          A műtét előtti konzultáció díja 6.000 Ft.
        </p>
        <p class="ar-title small">
          Bódítás
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_altatas_boditas"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title small">
          Gép altatás
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_altatas_gepi"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title small">
          Ivartalanítás
        </p>
        <p class="ar-desc">
          (+ altatási díj+gyógyszerköltség)
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_mutet_macska"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title small" />
        <p class="ar-desc">
          <strong>Nőstény kutya</strong>
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_mutet_kutya_szuka"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title small" />
        <p class="ar-desc">
          <strong>Hím kutya</strong>
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_mutet_kutya_kan"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>

        <p class="ar-title small" />
        <p class="ar-desc">
          <strong>Fogászati</strong> beavatkozás: mindig bódításban/altatásban végezzük, előtte kérjen időpontot
          állapotfelmérésre
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_mutet_fogaszat"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>

        <p class="ar-title small" />
        <p class="ar-desc font-italic font-weight-bolder mt-4">
          A műtétek és egyéb bódításos beavatkozások árairól érdeklődjön személyesen, vagy telefonon keresztül.
        </p>
      </b-card>
    </b-container>
  </main>
</template>
<script>
export default {
  name: 'Arlista',
  data () {
    return {
      arak_alt: [
        { title: 'Vizsgálati díj (kutya, macska)', price: '15.000 Ft' },
        { title: 'Vizsgálati díj sürgősségi (bejelentkezés nélkül)', price: '20.000 Ft' },
        { title: 'Vizsgálati díj ultrahanggal (hasi)', price: '20.000 Ft' },
        { title: 'Ultrahangos vizsgálat (hasi, egy szerv)', price: '9.000 Ft' },
        { title: 'Ultrahangos vizsgálat (hasi, több szerv)', price: '16.000 Ft' },
        { title: 'Kontroll vizsgálat (kutya, macska)', price: '8.000 Ft' },
        { title: 'Utókezelés', price: '4.000 Ft' },
        { title: 'Műtét előtti konzultáció', price: '6.000' },
        { title: 'Műtét utáni konzultáció', price: 'Ingyenes' },
        { title: 'Microchip beültetés + regisztráció', price: '18.000 Ft' },
        { title: 'Kisállat egészségügyi könyv', price: '2.500 Ft' },
        { title: 'Útlevél kiállítás', price: '18.000 Ft' },
        { title: 'Videotoszkópos fülvizsgálat', price: '8.000 Ft' },
        { title: 'Fültisztítás', price: '7.000 Ft' },
        { title: 'Fülszőr tépés', price: '4.500 Ft' },
        { title: 'Fülmosás', price: '4.500 Ft' },
        { title: 'Bűzmirigy toalett', price: '5.500 Ft' },
        { title: 'Karomvágás', price: '5.500 Ft' },
        { title: 'Hánytatás', price: '16.000 Ft' },
        { title: 'Fluorescein festés', price: '7.000 Ft' },
        { title: 'Schirmer teszt', price: '6.000 Ft' },
        { title: 'Vércukormérés', price: '4.500 Ft' },
        { title: 'Vizeletvizsgálat tesztcsíkkal', price: '4.000 Ft' },
        { title: 'Vérvétel', price: '5.500 Ft' },
        { title: 'Mintavétel', price: '4.000 Ft' },
        { title: 'Receptírás', price: '2.500 Ft' },
      ],
      arak_kutya: [
        { title: 'Veszettség', price: '14.000 Ft' },
        { title: 'Kombinált vakcina', price: '16.000 Ft' },
        { title: 'Veszettség + kombinált', price: '18.000 Ft' },
        { title: 'Kennel köhögés', price: '16.000 Ft' },
        { title: 'Lyme kór elleni', price: '16.000 Ft' },
        { title: 'Microsporiasis (gomba) ', price: '16.000 Ft' },
      ],
      arak_macska: [
        { title: 'Veszettség', price: '14.000 Ft' },
        { title: 'Kombinált', price: '16.000 Ft' },
        { title: 'Veszettség + kombinált', price: '18.000 Ft' },
        { title: 'Leukosis elleni', price: '14.000 Ft' },
        { title: 'Leukosis + kombinált', price: '18.000 Ft' },
        { title: 'Leukosis + kombinált + veszettség', price: '22.000 Ft' },
        { title: 'Microsporiasis (gomba)', price: '16.000 Ft' },
      ],
      arak_goreny: [
        { title: 'Kombinált', price: '14.000 Ft' },
        { title: 'Veszettség + kombinált', price: '18.500 Ft' },
      ],
      arak_nyul: [
        { title: 'Pestorin Mormyx', price: '13.000 Ft' },
        { title: 'Nobivac Myxo-RHD PLUS', price: '16.500 Ft' },
      ],
      arak_gyors_tesztek: [
        { title: 'FIV/FeLV', price: '13.000 Ft' },
        { title: 'Giardia', price: '12.000 Ft' },
        { title: 'Vastagcsepp + szívférgesség', price: '14.000 Ft' },
        { title: 'Parvo/Corona/Giardia Uranotest gyorsteszt', price: '15.000 Ft' },
      ],
      arak_infuzio: [
        { title: 'Vénakanüllel', price: '12.500 Ft' },
        { title: '2. alkalomtól', price: '6.000 Ft' },
        { title: 'Subcutan', price: '4.000 Ft' },
      ],
      arak_kotes: [
        { title: 'Fedőkötés egyszerű', price: '4.000 Ft' },
        { title: 'Fedőkötés bonyolult', price: '8.000 Ft' },
      ],
      arak_altatas_boditas: [
        { title: '10 kg-ig', price: '11.500 Ft' },
        { title: '10-20 kg-ig', price: '14.000 Ft' },
        { title: '20-40 kg-ig', price: '17.000 Ft' },
      ],
      arak_altatas_gepi: [
        { title: '10 kg-ig', price: '21.000 Ft' },
        { title: '10-20 kg-ig', price: '24.000 Ft' },
        { title: '20-40 kg-ig', price: '28.000 Ft' },
      ],
      arak_mutet_macska: [
        { title: 'Nőstény macska', price: '27.500 Ft' },
        { title: 'Kandúr macska ', price: '17.000 Ft' },
      ],
      arak_mutet_kutya_szuka: [
        { title: '10 kg-ig', price: '42.000 Ft' },
        { title: '10-20 kg-ig', price: '45.000 Ft' },
        { title: '20-40 kg-ig', price: '48.000 Ft' },
      ],
      arak_mutet_kutya_kan: [
        { title: '10 kg-ig', price: '25.000 Ft' },
        { title: '10-20 kg-ig', price: '27.500 Ft' },
        { title: '20-40 kg-ig', price: '32.000 Ft' },
      ],
      arak_mutet_fogaszat: [
        { title: 'ultrahangos fogkő depuráció', price: '12.000-18.000 Ft' },
        { title: 'polírozás', price: '5.000 Ft' },
        { title: 'foghúzás (tejfog, maradandó fog)', price: '3.500-6.000 Ft' },
      ],
    }
  },
}
</script>
<style
  lang="scss"
  scoped
>
@import '../sass/settings';

.card {
  border-radius: 0;
  border: none;
  padding: 60px 40px;

  .card-body {
    margin: 0;
    padding: 0;
  }

  .list-group {
    border: none;
    max-width: 860px;
    margin: auto;

    .list-group-item {
      border: none;
      display: grid;
      gap: 1em;
      border-radius: 0;

      @include media-breakpoint-up(sm) {
        grid-template-columns: 1fr max-content;
      }

      &:nth-of-type(2n+1) {
        background: #f8f8f8;
      }

      &:hover {
        background-color: #dedace;
      }

      p {
        display: block;
        margin: 0;
      }

      .ar-list-price {
        text-align: right;
      }
    }
  }
}

@media screen and (max-width: 765px) {
  main {
    background-color: #fff;

    .main-title {
      margin-left: 20px;
    }
  }
  .container {
    margin: 0;
    padding: 0;
    width: 100vw;
    max-width: 100%;

    & > .card {
      width: calc(100vw - 20px);
      margin: 0;
      padding: 20px;
    }
  }
}
</style>
